import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Layout from '../components/layout';
import IconLocation from "../images/icons/theme/map/marker-1.svg";
import IconSun from "../images/icons/theme/weather/sun.svg";
import SocialShare from '../components/SocialShare';
import { replaceVariables, replaceVariablesInObject } from '../utils/replaceDate';
import CalendlyButton from '../components/CalendlyPopupButton';
import { countryToCode, agencyTranslations } from '../utils/helpers';

const MAX_COUNTRY_LINKS = 20;

const CountryCategoryTemplate = ({ data, pageContext, location: { pathname } }) => {
    const { country, category, currentPage, numPages, visibleName, limit, totalAgencies, localizedAgencies } = pageContext;
    const featuredAgency = data.featuredAgency.edges[0]?.node;
    const regularAgencies = data.allAgencies.edges;
    const allCountries = data.allCountries.group;
    //console.log(agencies, country, category, visibleName);

    const [showAllCountries, setShowAllCountries] = useState(false);
    const displayedCountries = showAllCountries ? allCountries : allCountries.slice(0, MAX_COUNTRY_LINKS);

    // Add this function to handle country names
    const formatCountryName = (name) => {
      const lowercaseName = name.toLowerCase();
      const countriesWithThe = [
        'united states',
        'netherlands',
        'united kingdom',
        'philippines',
        'czech republic',
        'dominican republic',
        'central african republic',
        'united arab emirates',
        'democratic republic of the congo',
        'bahamas',
        'gambia',
        'vatican city'
      ];
      return countriesWithThe.includes(lowercaseName) ? `the ${name}` : name;
    };

    // Function to generate the correct path for pagination links
    const getPagePath = (pageNum) => {
      const basePath = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
      const lastSegment = basePath.split('/').pop();
      if (isNaN(lastSegment)) {
        return pageNum === 1 ? basePath : `${basePath}/${pageNum}`;
      } else {
        const newBasePath = basePath.split('/').slice(0, -1).join('/');
        return pageNum === 1 ? newBasePath : `${newBasePath}/${pageNum}`;
      }
    };

  const seoTitle = `${visibleName} ${localizedAgencies} in ${formatCountryName(country)}`;
  const seoDescription = `Find top ${visibleName} ${localizedAgencies} in ${formatCountryName(country)}`;

  return (
    <Layout
      hideFooter={true}
      isDirectoryPage={true}
      hideHeader={true}
      footerBgClass="bg-white"
      meta={{
        seo: {
          title: seoTitle,
          description: seoDescription,
        },
        path: pathname,
      }}
    >
      <div className="country-category-template-page">
        <section className="has-divider o-hidden">
          <div className="container layer-2 py-3">
            <div className="row">
              <div className="col-12 col-lg-8 text-left">
              <h1 className="display-4 py-1 text-capitalize">Top {visibleName} {localizedAgencies} in {formatCountryName(country)}</h1>
                {/* <p className="">
                  On User Experience, Interface & Product Design: Series of
                  articles, strategies and best practices dedicated to
                  Startups, Entrepreneurs & Designers.
                </p> */}
              </div>

              {/* <div className="col-12 mt-3 text-left row align-items-center publications-section">
                <p className="col-12 col-sm-auto lead">Featured in</p>
              </div> */}
            </div>
          </div>
          {/*<div className="divider">
            <SvgDivider1 />
          </div>*/}
        </section>

        <section className='pt-4'>
          <div className="container">
            <div className="row">
            {featuredAgency && (
                <React.Fragment key={featuredAgency.id}>
                  <div className="col-12 mb-4">
                    <div className="card card--no-hover h-100 shadow-sm position-relative bg-primary-alt">
                      <div className="position-absolute" style={{
                        top: '2rem',
                        left: '-2rem',
                        width: '60px',
                        height: '60px',
                        backgroundColor: 'var(--primary)',
                        color: 'white',
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                        zIndex: 1
                      }}>
                        {currentPage === 1 ? '1' : '✦'}
                      </div>
                      <style>
                        {`
                          @media (max-width: 768px) {
                            .position-absolute {
                              top: 2rem !important;
                              right: 2rem !important;
                              left: auto !important;
                            }
                          }
                        `}
                      </style>
                      <div className="card-body d-flex flex-column">
                        <div className='row'>
                          <div className="col-12 d-flex align-items-center mb-3">
                              <div style={{ width: '60px', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', border: '1px solid #e0e0e0', borderRadius: '8px', marginRight: '15px' }}>
                                <GatsbyImage
                                  image={featuredAgency.logo?.gatsbyImageData}
                                  alt={featuredAgency.name}
                                  className="m-1"
                                  style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                                />
                              </div>
                              <h3 className="mb-0">
                                <Link className='text-primary-3 hover-text' to={`/profile/${featuredAgency.slug}`}>
                                  {featuredAgency.name || 'Agency Name'}
                                </Link>
                              </h3>
                            </div>
                        </div>
                        <div className="row">
                          <div className="col-md-8">
                            <ReactMarkdown>{featuredAgency.shortDescription || 'Short description placeholder'}</ReactMarkdown>
                            <div className="row bg-primary-alt p-2 border-radius-8 rounded mt-4 mb-4">
                              <div className="col-md-4">
                                <div>
                                  <span className="text-secondary">Min. Project Size:</span>
                                </div>
                                <div>
                                  <span className="font-weight-bold">{featuredAgency.minProjectSize || 'N/A'}</span>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div><span className="text-secondary">Avg. Hourly Rate:</span></div>
                                <div><span className="font-weight-bold">{featuredAgency.avgHourlyRate ? `${featuredAgency.avgHourlyRate}` : 'N/A'}</span></div>
                              </div>
                              <div className="col-md-4">
                                <div><span className="text-secondary">Employees:</span></div>
                                <div><span className="font-weight-bold">{featuredAgency.employees || 'N/A'}</span></div>
                              </div>
                            </div>
                            <div className='d-flex flex-row'>
                              <div className="mb-2 mr-2">
                                <div><span className="text-secondary"><IconSun className="injected-svg icon bg-primary-3" /></span> <span className="font-weight-bold">{featuredAgency.founded || 'N/A'}</span></div>
                              </div>
                              {/* <div className="mb-2">
                                <div><span className="text-secondary"><IconLocation className="injected-svg icon bg-primary-3" /></span> <span className="font-weight-bold">{featuredAgency.city}, {featuredAgency.state}</span></div>
                              </div> */}
                            </div>
                          </div>
                          <div className="col-md-4">
                            {featuredAgency.thumbnail && (
                              <div className='mb-3' style={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}>
                                <GatsbyImage
                                  image={featuredAgency.thumbnail.gatsbyImageData}
                                  alt={`${featuredAgency.name} thumbnail`}
                                  className="rounded"
                                  objectPosition="top"
                                  style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: 'auto',
                                    maxHeight: '20rem',
                                    objectPosition: 'top'
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 text-right">
                            <Link to={`/profile/${featuredAgency.slug}`} className="btn btn-primary btn-lg">View Profile</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}

              {regularAgencies.map(({ node }, index) => {
                const globalIndex = (currentPage - 1) * limit + index + 2;
                return (
                <React.Fragment key={node.id}>
                  <div className="col-12 mb-4">
                    <div className="card card--no-hover h-100 shadow-sm position-relative">
                      <div className="position-absolute" style={{
                        top: '2rem',
                        left: '-2rem',
                        width: '60px',
                        height: '60px',
                        backgroundColor: 'var(--primary)',
                        color: 'white',
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                        zIndex: 1
                      }}>
                        {globalIndex}
                      </div>
                      <style>
                        {`
                          @media (max-width: 768px) {
                            .position-absolute {
                              top: 2rem !important;
                              right: 2rem !important;
                              left: auto !important;
                            }
                          }
                        `}
                      </style>
                      <div className="card-body d-flex flex-column">
                        <div className='row'>
                          <div className="col-12 d-flex align-items-center mb-3">
                              <div style={{ width: '60px', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', border: '1px solid #e0e0e0', borderRadius: '8px', marginRight: '15px' }}>
                                <GatsbyImage
                                  image={node.logo?.gatsbyImageData}
                                  alt={node.name}
                                  className="m-1"
                                  style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                                />
                              </div>
                              <h3 className="mb-0">
                                <Link className='text-primary-3 hover-text' to={`/profile/${node.slug}`}>
                                  {node.name || 'Agency Name'}
                                </Link>
                              </h3>
                            </div>
                        </div>
                        <div className="row">
                          <div className="col-md-8">
                            <ReactMarkdown>{node.shortDescription || 'Short description placeholder'}</ReactMarkdown>
                            <div className="row bg-primary-alt p-2 border-radius-8 rounded mt-4 mb-4">
                              <div className="col-md-4">
                                <div>
                                  <span className="text-secondary">Min. Project Size:</span>
                                </div>
                                <div>
                                  <span className="font-weight-bold">{node.minProjectSize || 'N/A'}</span>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div><span className="text-secondary">Avg. Hourly Rate:</span></div>
                                <div><span className="font-weight-bold">{node.avgHourlyRate ? `${node.avgHourlyRate}` : 'N/A'}</span></div>
                              </div>
                              <div className="col-md-4">
                                <div><span className="text-secondary">Employees:</span></div>
                                <div><span className="font-weight-bold">{node.employees || 'N/A'}</span></div>
                              </div>
                            </div>
                            <div className='d-flex flex-row'>
                              <div className="mb-2 mr-2">
                                <div><span className="text-secondary"><IconSun className="injected-svg icon bg-primary-3" /></span> <span className="font-weight-bold">{node.founded || 'N/A'}</span></div>
                              </div>
                              <div className="mb-2">
                                <div><span className="text-secondary"><IconLocation className="injected-svg icon bg-primary-3" /></span> <span className="font-weight-bold">{node.city}, {node.state}</span></div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            {node.thumbnail && (
                              <div className='mb-3' style={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}>
                                <GatsbyImage
                                  image={node.thumbnail.gatsbyImageData}
                                  alt={`${node.name} thumbnail`}
                                  className="rounded"
                                  objectPosition="top"
                                  style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: 'auto',
                                    maxHeight: '20rem',
                                    objectPosition: 'top'
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 text-right">
                            <Link to={`/profile/${node.slug}`} className="btn btn-primary btn-lg">View Profile</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(globalIndex) % 3 === 0 && (
                    <div className="col-12 mb-4">
                      {globalIndex % 6 === 3 ? (
                        <div className="card card--no-hover bg-primary text-white text-center">
                          <div className="card-body">
                            <span className="badge badge-light text-primary-3 h4 mb-2 py-1 px-3">FREE UX Session</span>
                            <h3 className='text-white mb-1'>Not sure where to start?</h3>
                            <p className='text-white lead'>Get help with your project during our free UX session</p>
                            <CalendlyButton bntClass='btn btn-light btn-lg mt-2'/>
                            {/* <button className="btn btn-light btn-lg mb-2">Book a call</button> */}
                          </div>
                        </div>
                      ) : (
                        <div className="card card--no-hover bg-success text-white text-center">
                          <div className="card-body">
                            <span className="badge badge-light text-secondary h4 mb-2 py-1 px-3">Expert Advice</span>
                            <h3 className='text-white mb-1'>Need professional guidance?</h3>
                            <p className='text-white lead'>Consult with our experts to elevate your project</p>
                            <button className="btn btn-light btn-lg mb-2">Schedule Consultation</button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </React.Fragment>
              )})}
            </div>

            {/* Pagination */}
            {numPages > 1 && (
              <nav aria-label="Page navigation" className="mt-4">
                <ul className="pagination justify-content-center">
                  {currentPage > 1 && (
                    <li className="page-item">
                      <Link to={getPagePath(currentPage - 1)} className="page-link">
                        &laquo; Previous
                      </Link>
                    </li>
                  )}
                  
                  {Array.from({ length: numPages }, (_, i) => {
                    const pageNumber = i + 1;
                    if (
                      pageNumber === 1 ||
                      pageNumber === numPages ||
                      (pageNumber >= currentPage - 2 && pageNumber <= currentPage + 2)
                    ) {
                      return (
                        <li key={`pagination-number${pageNumber}`} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                          <Link to={getPagePath(pageNumber)} className="page-link">
                            {pageNumber}
                          </Link>
                        </li>
                      );
                    } else if (pageNumber === currentPage - 3 || pageNumber === currentPage + 3) {
                      return (
                        <li key={`pagination-ellipsis-${pageNumber}`} className="page-item disabled">
                          <span className="page-link">...</span>
                        </li>
                      );
                    }
                    return null;
                  })}
                  
                  {currentPage < numPages && (
                    <li className="page-item">
                      <Link to={getPagePath(currentPage + 1)} className="page-link">
                        Next &raquo;
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            )}

          </div>
        </section>

        <section className="py-4 bg-light">
          <div className="container">
            <h2 className="text-center mb-4">Explore UX Agencies in Other Countries</h2>
            <div className="row">
              {displayedCountries
                .filter(({ totalCount }) => totalCount > 5)
                .sort((a, b) => b.totalCount - a.totalCount)
                .map(({ fieldValue: countryName, totalCount }) => {
                  if (countryName === country) return null; // Skip current country
                  const countryCode = countryToCode[countryName] || countryName.toLowerCase();
                  const localizedAgencies = (agencyTranslations[countryCode.toLowerCase()] || 'agencies').toLowerCase();
                  return (
                    <div key={countryName} className="col-md-3 col-sm-6 mb-3">
                      <Link to={`/${countryCode}/${localizedAgencies}/${category.toLowerCase()}`} className="text-decoration-none">
                        {countryName}
                      </Link>
                    </div>
                  );
                })}
            </div>
            {allCountries.filter(({ totalCount }) => totalCount > 5).length > MAX_COUNTRY_LINKS && (
              <div className="text-center mt-4">
                <button 
                  className="btn btn-primary" 
                  onClick={() => setShowAllCountries(!showAllCountries)}
                >
                  {showAllCountries ? 'Show Less' : 'View More Countries'}
                </button>
              </div>
            )}
          </div>
        </section>
        {/* <SocialShare link={pathname} /> */}
      </div>
    </Layout>
  );
};

export default CountryCategoryTemplate;

export const query = graphql`
  query CountryCategoryTemplate($country: String!, $category: String!, $skip: Int!, $limit: Int!) {
    featuredAgency: allDatoCmsAgency(
      filter: { 
        slug: { eq: "adam-fard-ux-studio" }
      }
      limit: 1
    ) {
      edges {
        node {
          id
          name
          shortDescription
          logo {
            gatsbyImageData(width: 200, layout: CONSTRAINED)
          }
          thumbnail {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
          }
          minProjectSize
          avgHourlyRate
          employees
          city
          state
          category {
            categoryName
            visibleName
          }
          founded
          slug
        }
      }
    }  
  allAgencies: allDatoCmsAgency(
      filter: { 
        country: { eq: $country }, 
        category: { elemMatch: { categoryName: { eq: $category } } }
        avgHourlyRate: { nin: ["$25 - $49 / hr", "$50 - $99 / hr", "< $25 / hr", "Undisclosed"] }
        slug: { ne: "your-featured-agency-slug" }
      }
      limit: $limit
      skip: $skip
      sort: { fields: [name], order: ASC }
    ) {
      edges {
        node {
          id
          name
          shortDescription
          logo {
            gatsbyImageData(width: 200, layout: CONSTRAINED)
          }
          thumbnail {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
          }
          minProjectSize
          avgHourlyRate
          employees
          city
          state
          category {
            categoryName
            visibleName
          }
          founded
          slug
        }
      }
    }
  allCountries: allDatoCmsAgency(
      filter: { 
        avgHourlyRate: { nin: ["$25 - $49 / hr", "$50 - $99 / hr", "Undisclosed"] },
        country: { ne: "Not specified" }
      }
      
    ) {
      group(field: country) {
        fieldValue
        totalCount
      }
    }
  }
`;
